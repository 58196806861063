
import comarcas from "@/core/data/comarcas";
import lugaresEspaciosColectivos from "@/core/data/lugaresEspaciosColectivos";
import { showModal } from "@/core/helpers/dom";
import { Reserva } from "@/store/models";
import { Documento } from "@/store/models/Documento";
import ServiciosProgramadosModule from "@/store/modules/ServiciosProgramadosModule";
import moment from "moment";
import "moment/locale/es";
import { useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

interface ServicioProgramado {
  comarcaId: string;
  nombre: string;
  descripcion: string;
  plazas: number;
  direccion?: string | null;
  inscripcionesAbiertas: boolean;
  lugarPrestacion?: string | null;
  lugarEspacioColectivo?: string | null;
  otrosEspacioColectivo?: string | null;
  inicioPrevisto: Date;
  finPrevisto: Date;
  fechas: Array<string> | [];
}

export const useUsername = ({ user }) => {
  return { username: user.name };
};

export default defineComponent({
  name: "kt-landing",

  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const token = route.query.act;

    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);

    const comarcaValor = ref<string>();
    const servicioId = ref<string>();
    const documentos = ref<Documento[]>([]);
    const iconoCls = ref<string>();

    onBeforeMount(() => {
      if (token) {
        module.fetchById({ formularioId: token as string });
      }
    });
    const datosServicio = computed(() => module.servicio);

    const nextStepUrl = computed(() => {
      const pathFijo = new String("/familias?act=");
      const pathVariable = token || "";
      return pathFijo.concat(pathVariable.toString());
    });
    watch(datosServicio, () => {
      if (!datosServicio.value) return;

      iconoCls.value = datosServicio.value.iconoCls;

      resetForm({
        values: {
          comarcaId: datosServicio.value.comarcaId,
          nombre: datosServicio.value.nombre,
          descripcion: datosServicio.value.descripcion,
          lugarPrestacion: "ESPACIO",
          lugarEspacioColectivo:
            datosServicio.value.servicio.lugarEspacioColectivo,
          otrosEspacioColectivo:
            datosServicio.value.servicio.otrosEspacioColectivo,
          plazas: datosServicio.value.plazas,
          direccion: datosServicio.value.servicio.direccion,
          inscripcionesAbiertas: datosServicio.value.inscripcionAbierta,
          inicioPrevisto: datosServicio.value.servicio.inicioPrevisto,
          finPrevisto: datosServicio.value.servicio.finPrevisto,
          fechas: datosServicio.value.servicio.fechas,
        },
      });
      documentos.value = datosServicio.value.documentos || [];
      comarcaValor.value = datosServicio.value.comarcaId;
      servicioId.value = datosServicio.value.servicio.id;
    });

    const { resetForm } = useForm<ServicioProgramado>({});
    const { value: comarcaId } = useField("comarcaId");
    const { value: nombre } = useField("nombre");
    const { value: descripcion } = useField("descripcion");
    const { value: lugarPrestacion } = useField("lugarPrestacion");
    const { value: lugarEspacioColectivo } = useField("lugarEspacioColectivo");
    const { value: otrosEspacioColectivo } = useField("otrosEspacioColectivo");
    const { value: plazas } = useField<number>("plazas");
    const { value: inicioPrevisto } = useField<Date>("inicioPrevisto");
    const { value: finPrevisto } = useField<Date>("finPrevisto");
    const { value: inscripcionesAbiertas } = useField("inscripcionesAbiertas");
    const { value: direccion } = useField("direccion");
    const { value: fechas } = useField<Reserva[]>("fechas");

    const i18n = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };
    return {
      i18n,
      token,
      datosServicio,
      comarcas,
      comarcaId,
      nombre,
      descripcion,
      lugarPrestacion,
      lugarEspacioColectivo,
      lugaresEspaciosColectivos,
      otrosEspacioColectivo,
      plazas,
      direccion,
      inscripcionesAbiertas,
      documentos,
      fechas,
      nextStepUrl,
      inicioPrevisto,
      finPrevisto,
      inicioPrevistoFormateado: computed(() => {
        return moment(inicioPrevisto.value).format("LL");
      }),
      finPrevistoFormateado: computed(() => {
        return moment(finPrevisto.value).format("LL");
      }),
      iconoCls,
    };
  },

  methods: {
    scrollTo(refName) {
      var element = this.$refs[refName] as HTMLAnchorElement;
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },

    mostrarModal(src: string, tipo: string) {
      const imagenCompleta = document.getElementById("imagenCompleta");
      if (!imagenCompleta) {
        console.error("Los elementos del DOM no se encontraron.");
        return;
      }
      // Si el tipo de archivo es imagen mostrar la previsualización
      if (tipo === "imagen") {
        imagenCompleta.setAttribute("src", src);
      } else {
        return;
      }
      showModal(document.getElementById("documentoModal"));
    },
    onImagenClick(blob: string, nombre: string) {
      const tipo = this.getMimeType(blob);
      if (tipo === "imagen") {
        this.mostrarModal(blob, tipo);
      } else {
        this.descargarArchivo(blob, nombre);
      }
    },
    descargarArchivo(blob: string, nombre: string) {
      // Creamos un enlace temporal para iniciar la descarga
      const enlace = document.createElement("a");
      enlace.href = blob;
      enlace.download = nombre;
      document.body.appendChild(enlace);
      enlace.click();
      document.body.removeChild(enlace);
    },
    getMimeType(base64String: string): string {
      const contentType = base64String.substring(5, base64String.indexOf(";"));
      switch (contentType) {
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
        case "image/gif":
          return "imagen";
        case "application/pdf":
          return "pdf";
        default:
          return "";
      }
    },
  },
});
